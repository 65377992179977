import React from "react";
import { Seo } from "../components/Seo";
import { Header, Footer } from "../components/layout/index";
import { BannerImage } from "../components/atoms/BannerImage";

const Legal = () => {
  const css = {
    subTitle: "text-2xl mt-16 mb-8",
    sectionTitle: "text-xl mt-8 mb-4",
  };

  const Paragraph = ({ children }) => <p className="my-4">{children}</p>;

  return (
    <>
      <Seo title="Mentions Légales" />
      <Header />
      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-gray-900 text-center">
            Mentions légales
          </h2>
          <div className="mt-12">
            <dl className="space-y-10">
              <div className="max-w-2xl">
                <h2 className={css.subTitle}>Editeur du site</h2>
                <Paragraph>
                  Les sites www.omnidoc.fr et www.app.omnidoc.fr sont édités par
                  la société OmniDoc (ci-après "l’Editeur").
                  <br />
                  <br />
                  <span className="italic">
                    Société par actions simplifiée au capital de 1.467 €,
                    inscrite au RCS de Paris sous le N°847 506 144 dont le siège
                    social est 9 RUE DES COLONNES 75002 PARIS.
                    <br />
                    <br />
                    Adresse e-mail : contact@omnidoc.fr
                    <br />
                    N° de TVA intracommunautaire : FR 34847506144
                    <br />
                    Directeur de la publication : Monsieur Baptiste Truchot,
                    Président
                  </span>
                </Paragraph>
                <h2 className={css.subTitle}>
                  Hébergement des données de santé
                </h2>
                <Paragraph>
                  Les données de santé à caractère personnel collectées et
                  traitées dans le cadre de l’utilisation de la plateforme
                  Omnidoc sont hébergées auprès d’un hébergeur agréé de santé,
                  conformément aux dispositions de l’article L.1111-8 du Code de
                  la santé publique.
                  <br />
                  <br />
                  En particulier, les données de santé et les serveurs sont
                  hébergés par la société CLARANET, Société par Actions
                  Simplifiée au capital de 5.332.300 €, inscrite au RCS de
                  Rennes sous le N° B 419 632 286, dont le siège social est, 2
                  rue Landelles, 35510 Cesson-Sévigné.
                  <br />
                  <br />
                  <span className="italic">
                    Numéro de téléphone : 02 99 12 57 57
                    <br />
                    Adresse e-mail : info@fr.clara.net
                    <br />
                    N° de TVA intracommunautaire : FR 24 419 632 286
                    <br />
                    Directeur de la publication : Olivier Beaudet, en qualité de
                    Directeur Général
                  </span>
                </Paragraph>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <BannerImage />
      <Footer />
    </>
  );
};

export default Legal;
